<template>
  <div>
    <component :is="layout">

    </component>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {

    };
  },
  computed: {
    layout() {
      return () => import('../containers/layouts/Index')
    }
  },
  methods: {}
};
</script>
